/** @jsx jsx */
import { jsx } from "theme-ui"
import Banner from "@components/Banner"
import { rem } from "@src/theme"
import Spacer from "@components/Spacers"
const BannerBlock = ({ title, children, noMaxWidth, noMt }) => (
  <div
    sx={{
      width: noMaxWidth ? "100%" : ["90%"],
      maxWidth: noMaxWidth ? "initial" : rem(715),
      mx: "auto",
    }}
  >
    <div
      sx={{
        textAlign: "center",
      }}
    >
      <Banner title={title} />
      <Spacer.Medium />
    </div>
    {children}
  </div>
)

export default BannerBlock
