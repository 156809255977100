/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import TwoColumnSection, {
  Block as ColumnBlock,
} from "@components/TwoColumnSection"
import { Grid } from "@theme-ui/components"
import Section from "@components/Section"
import Container from "@components/Container"
import { rem } from "@src/theme"
import { DonateSection } from "@components/ImpactFooter"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import PartnersSection from "@components/PartnersSection"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import AccordionWithBanner from "@components/Accordion/WithBanner"
import Spacer from "@components/Spacers"
import BannerBlock from "@components/BannerBlock"
import ArticleCard from "@components/ArticleCard"
import { getSlug } from "@helpers/utils/getSlug"

const Governance = ({ data, ...props }) => {
  const {
    imageTextBlocks,
    accordion,
    partnersLogo,
    ogdescription,
  } = data.pageData.frontmatter
  const { articles } = data

  return (
    <Layout>
      <SEO
        title="Governance"
        pathname={props.location.pathname}
        description={ogdescription}
      />
      <TwoColumnSection
        col1={
          <ColumnBlock
            styles={{
              bg: "purple",
              color: "pink",
            }}
          >
            <CTAHeadingBlock heading="Governance" />
          </ColumnBlock>
        }
        col2={
          <ColumnBlock
            styles={{
              bg: "turquoise",
              color: "darkPurple",
            }}
          >
            <CTAHeadingBlock
              heading="HOW WE LEAD"
              body="Minus18 Foundation Ltd is a not-for-profit public company endorsed as a Deductible Gift Recipient by the ATO. We’re committed to strong and transparent governance, led by an effective board."
              smallHeading
              widthLimit
            />
          </ColumnBlock>
        }
      />

      <Section
        sx={{
          bg: "pink",
        }}
      >
        <BannerBlock title="Quick links" noMt />

        <Container>
          <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
            {articles.edges.map(item => (
              <ArticleCard
                key={item.node.id}
                data={{
                  ...item,
                  slug: getSlug(item),
                  preview: item.node.frontmatter.cardPreview,
                }}
                linkPrefix="news"
              />
            ))}
          </Grid>
        </Container>
      </Section>

      <GradientWrapper>
        <ImageTextBlocksList blocks={imageTextBlocks} />

        <Spacer.Large />
      </GradientWrapper>

      <AccordionWithBanner
        items={accordion.accordionItems}
        title="Our work"
        noMaxWidthBanner
      />

      <PartnersSection img={partnersLogo} />

      <DonateSection />
    </Layout>
  )
}

export default Governance

export const pageQuery = graphql`
  query($id: String!) {
    pageData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        accordion {
          ...Accordion
        }
        imageTextBlocks {
          ...ImageTextBlocks
        }
        partnersLogo
        ogdescription
      }
    }

    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/news/*.md" }
        frontmatter: { tags: { in: ["governance"] } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            cardPreview
            tags
            image
          }
        }
      }
    }
  }
`

const GradientWrapper = styled.div`
  background: #fff;
`
