/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"

const TwoColumnBlock = ({ col1, col2, reverseMobile }) => (
  <Grid columns={[1, null, 2, 2]} gap={0}>
    <div
      sx={{
        gridRow: reverseMobile ? [2, null, 1] : null,
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      {col1}
    </div>

    <div
      sx={{
        gridRow: reverseMobile ? [1, null, 1] : null,
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      {col2}
    </div>
  </Grid>
)

export default TwoColumnBlock
