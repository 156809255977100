/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import IconLink from "@components/IconLink"
import Section from "@components/Section"
import { BodyLarge } from "@components/primitives/Text"
import { rem } from "@src/theme"
import TwoColumnBlock from "@components/TwoColumnBlock"
import Spacer from "@components/Spacers"
import { Themed } from '@theme-ui/mdx'


const PaddedBox = ({ children, noPb, noPt, index, ...props }) => (
  <div
    sx={{
      px: "5vw",
      ...props.style,
    }}
  >
    <Spacer.Medium />
    {children}
    <Spacer.Medium />
  </div>
)

const ImageTextBlocksList = ({ noPt, blocks }) => (
  <InfoImageBlocksContainer>
    <Section noPb noPt>
      {noPt ? null : (
        <Spacer.Large
          styles={{
            display: ["none", null, "block"],
          }}
        />
      )}
      {blocks && blocks.map(
        ({ heading, image, text, links = [], bg = "transparent" }, index) =>
          index % 2 === 0 ? (
            <div key={index}>
              <TwoColumnBlock
                col1={
                  <div
                    sx={{
                      bg,
                      height: "100%",
                    }}
                  >
                    <PaddedBox>
                      <Themed.h2
                        sx={{
                          m: 0,
                        }}
                      >
                        {heading}
                      </Themed.h2>
                      <Spacer.XSmall />
                      <BodyLarge style={{ m: 0 }}>{text}</BodyLarge>
                      <Spacer.Small />
                      {links && links.length ? (
                        <LinksWrapper
                          sx={{
                            display: "flex",
                            flexDirection: ["column", "column", "row", "row"],
                            flexWrap: "wrap",
                          }}
                        >
                          {links.map((item, index) => (
                            <div key={index}>
                              <IconLink {...item} type="arrowRight" />
                              {index !== links.length - 1 && <Spacer.XSmall />}
                            </div>
                          ))}
                        </LinksWrapper>
                      ) : null}
                    </PaddedBox>
                  </div>
                }
                col2={
                  <img
                    className="object-fit"
                    src={image}
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                }
              />
              {index !== blocks.length - 1 && <Spacer.Large />}
            </div>
          ) : (
            <div key={index}>
              <TwoColumnBlock
                reverseMobile
                col1={
                  <img
                    className="object-fit"
                    src={image}
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                }
                col2={
                  <div
                    sx={{
                      bg,
                      height: "100%",
                    }}
                  >
                    <PaddedBox noPb>
                      <Themed.h2
                        sx={{
                          mb: 4,
                          mt: 0,
                        }}
                      >
                        {heading}
                      </Themed.h2>
                      <BodyLarge style={{ m: 0 }}>{text}</BodyLarge>
                      <Spacer.Small />
                      {links && links.length ? (
                        <LinksWrapper
                          sx={{
                            display: "flex",
                            flexDirection: ["column", "row", "row", "row"],
                            flexWrap: "wrap",
                          }}
                        >
                          {links.map((item, index) => (
                            <div key={index}>
                              <IconLink {...item} type="arrowRight" />
                              {index !== links.length - 1 && <Spacer.XSmall />}
                            </div>
                          ))}
                        </LinksWrapper>
                      ) : null}
                    </PaddedBox>
                  </div>
                }
              />
              {index !== blocks.length - 1 && <Spacer.Large />}
            </div>
          )
      )}
    </Section>
  </InfoImageBlocksContainer>
)

export default ImageTextBlocksList

const InfoImageBlocksContainer = styled.div`
  width: 100%;
  > section:first-child {
    div:last-child {
      margin-bottom: 0;
    }
  }
`

const LinksWrapper = styled.div`
  div:not(:last-child) {
    margin-right: ${rem(47)};
  }
`
