/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { connect } from "react-redux"

import Button from "@components/Button"
import ButtonLink from "@components/ButtonLink"
import { rem } from "@src/theme"
import Spacer from "@components/Spacers"
import { Themed } from '@theme-ui/mdx'


const DonateCard = ({ amount, text, openDonationModal }) => (
  <div
    sx={{
      px: [2, 4],
      py: [rem(24), rem(36)],
      bg: "white",
    }}
  >
    <div
      sx={{
        width: "90%",
        m: "0 auto",
        textAlign: "center",
        color: "darkPurple",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Themed.h2
        sx={{
          m: 0,
        }}
      >
        <Themed.h2 as="span">${amount}</Themed.h2>
      </Themed.h2>
      <Spacer.XSmall />
      <p sx={{ variant: "typography.sizing.bodyCopyLarge", m: 0 }}>{text}</p>
      <Spacer.Small />
      <div sx={{ display: ["none", "block"] }}>
        <Button bg="darkPurple" color="white" onClick={openDonationModal}>
          Donate
        </Button>
      </div>
      <div sx={{ display: ["block", "none"] }}>
        <ButtonLink
          to="https://www.givenow.com.au/minus18"
          bg="darkPurple"
          color="white"
          external
        >
          Donate
        </ButtonLink>
      </div>
    </div>
  </div>
)

export default connect(null, dispatch => ({
  openDonationModal: () => dispatch.donationModal.toggleDonationModal(true),
}))(DonateCard)
