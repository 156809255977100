/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "@components/Container"
import Section from "@components/Section"
import CalloutTextBlocks from "@components/CalloutTextBlocks"
import TwoColumnSection, {
  Block as ColumnBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import footerImage from "@static/images/impact-footer.jpg"
import DonateCard from "@components/DonateCard"
import { Grid } from "@theme-ui/components"
import { rem } from "@src/theme"
import { navigate } from "@reach/router"
import { BodyLarge } from "@components/primitives/Text"
import Spacer from "@components/Spacers"
import { Themed } from '@theme-ui/mdx'
import { graphql, useStaticQuery } from "gatsby"


export const DonateSectionTemplate = ({ donateCardsData }) => (
  <div
    sx={{
      bg: "yellow",
    }}
  >
    <Spacer.Large />
    <Container
      sx={{
        maxWidth: rem(1209),
      }}
    >
      <Themed.h2
        sx={{
          textAlign: "center",

          mt: 0,
        }}
      >
        Your donations keep us going
      </Themed.h2>

      <BodyLarge
        style={{
          mb: rem(80),
          textAlign: "center",
        }}
      >
        Minus18 Foundation Ltd is a registered Australian charity. All donations
        are tax-deductible.
      </BodyLarge>

      <Grid columns={[1, null, 2, 3]} gap={3}>
        {donateCardsData.map(({ amount, text }) => (
          <DonateCard amount={amount} text={text} key={amount} />
        ))}
      </Grid>
    </Container>
    <Spacer.Medium />
  </div>
)

export const DonateSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/globals/donateValues.md" }
        ) {
          frontmatter {
            description1
            value1
            description2
            value2
            description3
            value3
          }
        }
      }
    `
  )

  const { description1, value1, description2, value2, description3, value3 } = data.pageData.frontmatter

  return <DonateSectionTemplate donateCardsData={[
    {
      amount: value1, text:
        description1,
    },
    { amount: value2, text: description2, },
    { amount: value3, text: description3, }
  ]} />
}

export const ChampionSection = ({ }) => (
  <TwoColumnSection
    col1={
      <ColumnBlock
        styles={{
          bg: "purple",
          color: "pink",
        }}
      >
        <CTAHeadingBlock heading="CHAMPIONS FOR LGBTQIA+ YOUTH" />
      </ColumnBlock>
    }
    col2={
      <ColumnBlock
        styles={{
          bg: "turquoise",
        }}
      >
        <CTAHeadingBlock
          heading="CHANGING THE LIVES OF LGBTQIA+ YOUTH IN AUSTRALIA"
          body="We’re leading change, building social inclusion, and advocating for an Australia where all young people are safe, empowered, and surrounded by people that support them."
          buttonLabel="Read about our impact"
          onButtonClick={() => navigate("/about")}
          smallHeading
          widthLimit
        />
      </ColumnBlock>
    }
  />
)

export const WhatWeDoSection = ({ }) => (
  <Container>
    <Section>
      <CalloutTextBlocks
        heading="What we do"
        blocks={[
          {
            heading: "Life-affirming social inclusion",
            body:
              "Through a preventative model of mental health support, we tackle social isolation by creating spaces where young people belong.",
          },
          {
            heading: "Education & advocacy",
            body:
              "We transform communities through LGBTQIA+ training, resources, and digital campaigns that enable others to champion inclusivity.",
          },
          {
            heading: "Youth empowerment",
            body:
              "We believe in equipping young people with the skills they need to be at the forefront of driving change.",
          },
        ]}
      />
    </Section>
  </Container>
)

export const ImgSection = ({ }) => (
  <img
    src={footerImage}
    alt="Happy youth"
    sx={{
      width: "100%",
      display: "block",
    }}
  />
)

const ImpactFooter = ({ hideDonate, noPt, noPb }) => (
  <div>
    {!noPt && <Spacer.Large />}
    <ChampionSection />
    <WhatWeDoSection />
    <ImgSection />
    {!hideDonate && <DonateSection />}
    {!noPb && <Spacer.Large />}
  </div>
)

export default ImpactFooter
