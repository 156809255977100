/** @jsx jsx */
import { jsx } from "theme-ui"

const Body = ({ children, ...props }) => (
  <p
    sx={{
      variant: "typography.sizing.bodyCopy",
    }}
  >
    {children}
  </p>
)

export default Body

export const BodyLarge = ({ children, ...props }) => (
  <p
    sx={{
      variant: "typography.sizing.bodyCopyLarge",
      ...props.style,
    }}
  >
    {children}
  </p>
)
